






































































































































































































































































import Vue from "vue";
import apiClient from "@/services/apiClient";
import userManager from "@/services/userManager";
import moment from "moment";

export default Vue.extend({
  data() {
    return {
      dialog: false,
      form: false,
      dialogDelete: false,
      userManager,
      ruleRequired: (value) => !!value || this.$t("REQUIRED"),
      headers: [
        { text: "Tên khách hàng", value: "name" },
        { text: "Mã khách hàng", value: "codes", sortable: false },
        { text: "KD", value: "user_id" },
        { text: "Nguồn khách", value: "source" },
        !userManager.getImpersonateUserInfo() && {
          text: "Số điện thoại",
          value: "phone",
        },
        !userManager.getImpersonateUserInfo() && {
          text: "Địa chỉ",
          value: "address",
        },
        { text: "% Chiết khấu", value: "discount_percentage" },
        { text: "Tháng có đơn", value: "packageMonths" },
        { text: "Mặt hàng", value: "product_type" },
        { text: "Bảng giá", value: "pricing_item_id" },
        { text: "Trạng thái dấu", value: "stamp_status" },
        { text: "Ghi chú", value: "kt_note" },
        { text: "Ngày tạo", value: "created_at" },
        {
          text: this.$t("ACTIONS"),
          value: "actions",
          sortable: false,
        },
      ],
      options: {},
      loading: false,
      data: [],
      dataCount: 0,
      search: "",
      filterSource: "",
      filterStampStatus: "all",
      filterCreatedAt: "",
      filterPricingItemId: "",
      editedIndex: -1,
      editedItem: {
        codes: [],
      },
      defaultItem: {
        codes: [],
      },
      supportUsers: [],
      pricingItems: [],
      availableSourceMap: {
        k: "Khác",
        mkt: "Marketing",
        gt: "Giới thiệu",
        tk: "Tìm kiếm",
      },
      availableStampStatusMap: {
        yes: "Có",
        no: "Không cần",
        lack: "Thiếu",
      },
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Tạo khách hàng" : this.$t("EDIT");
    },
    availableSources() {
      const items = [];
      for (const k in this.availableSourceMap) {
        items.push({
          text: this.availableSourceMap[k],
          value: k,
        });
      }
      return items;
    },
    availableStampStatuses() {
      const items = [];
      for (const k in this.availableStampStatusMap) {
        items.push({
          text: this.availableStampStatusMap[k],
          value: k,
        });
      }
      return items;
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    options: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
  },
  methods: {
    async initialize() {
      this.loading = true;
      const filters = [
        {
          key: "codes",
          operator: "match",
          value: this.search,
        },
      ];
      if (this.filterSource) {
        filters.push({
          key: "source",
          operator: "=",
          value: this.filterSource,
        });
      }
      if (this.filterStampStatus !== "all") {
        filters.push({
          key: "stamp_status",
          operator: "=",
          value: this.filterStampStatus,
        });
      }
      if (this.filterCreatedAt) {
        const m = moment(this.filterCreatedAt);
        filters.push({
          key: "created_at",
          operator: ">=",
          value: m.startOf("month").format("Y-MM-DD"),
        });
        filters.push({
          key: "created_at",
          operator: "<=",
          value: m.endOf("month").format("Y-MM-DD"),
        });
      }
      if (this.filterPricingItemId) {
        filters.push({
          key: "pricing_item_id",
          operator: "=",
          value: this.filterPricingItemId,
        });
      }
      const { items, count } = await apiClient.customerGetAll({
        options: this.options,
        filters,
        extra: {
          load_packages: true,
        },
      });
      this.data = items;
      this.dataCount = count;
      this.loading = false;
    },
    packageMonths(packages) {
      const arr = [];
      for (const item of packages) {
        const m = moment(item.created_at);
        const year = m.year();
        if (year !== moment().year()) {
          continue;
        }
        const month = m.month() + 1;
        if (!arr.includes(month)) {
          arr.push(month);
        }
      }
      return arr;
    },
    editItem(item) {
      this.editedIndex = this.data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    deleteItem(item) {
      this.editedIndex = this.data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    async deleteItemConfirm() {
      this.closeDelete();
      await apiClient.customerDelete(this.editedItem);
      await this.initialize();
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    async save() {
      let result;
      if (this.editedIndex > -1) {
        let fields;
        if (userManager.getImpersonateUserInfo()) {
          fields = ["pricing_item_id"];
        } else if (userManager.checkRole(["kt"], true)) {
          fields = ["kt_note"];
        }
        result = await apiClient.customerUpdate(this.editedItem, fields);
      } else {
        result = await apiClient.customerCreate(this.editedItem);
      }
      if (result) {
        this.close();
        await this.initialize();
      }
    },
    async setStampStatus(item, value) {
      await apiClient.customerUpdateStampStatus(item.id, value);
      await this.initialize();
    },
  },
  async created() {
    {
      const supportUsers = await apiClient.userGetAll({
        filters: [
          {
            key: "roles",
            operator: "equal_to",
            value: "htkd",
          },
        ],
      });
      for (const user of supportUsers.items) {
        this.supportUsers.push({
          text: user.name,
          value: user.id,
        });
      }
    }
    {
      const pricingItems = await apiClient.pricingItemGetAll({
        limit: -1,
        extra: {
          customers: true,
        },
      });
      for (const pricingItem of pricingItems.items) {
        this.pricingItems.push({
          text: pricingItem.name,
          value: pricingItem.id,
        });
      }
    }
    if (userManager.checkRole(["kt"], true)) {
      this.headers = this.headers.filter((item) =>
        ["name", "codes", "user_id", "stamp_status", "kt_note", "actions"].includes(item.value)
      );
    }
  },
});
